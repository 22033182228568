import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import SidebarMenu from '../../components/common/SidebarMenu';

const PublicPage = React.lazy(() => import('@izi-logistics/common/lib/pages/Public'));

const Index = () => (
  <PreloadingComponent>
    <PublicPage config={{ client }} sideBarMenuContent={<SidebarMenu />} />
  </PreloadingComponent>
);
export default Index;
